

@media screen {
	.curalate-upload-container {
		&.curalate-upload-response {
			@apply tw-flex tw-flex-col tw-items-center;

			.curalate-upload-response-copy {
				font-size: 18px;
				margin: 40px 0;
				text-align: center;
			}

			.curalate-upload-controls > * + * {
				margin: 0 0 0 25px;
			}
		}

		.curalate-upload-form {
			.curalate-upload {
				@apply tw-flex tw-flex-col tw-divide-y tw-divide-gray-400 tw-space-y-5;
			}

			.curalate-upload-tabset {
				.curalate-upload-tabs {
					@apply tw-grid tw-grid-cols-3;
					.curalate-upload-tab {
						@apply tw-text-center;
						.curalate-upload-tab-button {
							@apply tw-w-full;
						}
						&.curalate-upload-tab-active {
							@apply tw-border-b-2 tw-pb-2;
							.curalate-upload-tab-button {
								@apply tw-text-black tw-font-normal;
							}
						}
					}
				}
			}

			.curalate-upload-files {
				.upload-files-droppable {
						@apply tw-flex tw-flex-row tw-flex-nowrap tw-justify-center tw-border tw-border-dashed tw-border-gray-400 md:tw-h-44 tw-h-20;
						:global .field-group.files-field-group.files-field-group-curalate-upload {
							@apply tw-flex md:tw-flex-row tw-flex-col tw-justify-center tw-flex-wrap tw-my-5;
						}
					}
				.files-field-group-heading {
					@apply tw-self-end tw-text-center tw-font-bold tw-w-full tw-mb-2;
					font-size: 18px;
				}
				.files-field-group-label-before {
					margin: 0 5px 0 0;
				}

				:global .upload-files-fieldset {
					margin: 0 0 20px;

					.files-field-group {
						@apply tw-flex tw-flex-row tw-flex-wrap tw-justify-center;
						margin: 20px 0;
					}
				}

				.files-label-curalate-upload {
					@apply tw-underline tw-text-blue tw-font-normal;
				}

				:global .files-control-curalate-upload {
					@apply tw-sr-only;

					&:focus ~ :local(.files-label-curalate-upload) {
						outline: dotted 1px theme('colors.gray.300');
						outline-offset: 2px;
					}
				}
			}

			.curalate-upload-instagram {
				@apply tw-flex tw-flex-col tw-items-center md:tw-h-44 tw-h-20 tw-justify-center;

				.curalate-upload-instagram-button {
					margin: 40px 0;
				}

				.curalate-upload-instagram-auth {
					display: none;
				}
			}

			.curalate-upload-facebook {
				@apply tw-flex tw-flex-col tw-items-center md:tw-h-44 tw-h-20 tw-justify-center;

				.curalate-upload-facebook-button {
					margin: 40px 0;
				}

				.curalate-upload-facebook-auth {
					display: none;
				}
			}

			.upload-images-fieldset {
				.upload-images-field-line {
					@apply tw-flex tw-flex-row tw-flex-wrap tw-mt-5;

					:global .upload-image-field-group {
						flex-basis: 33%;
						margin: 0 0 10px;
					}
					:local(.upload-image-label) {
						@apply tw-flex tw-flex-col tw-flex-nowrap tw-justify-center tw-items-center;
						margin: 0 10px;
						opacity: 0.5;
						padding: 0;

						&.upload-image-label-selected {
							opacity: 1;
						}
					}
				}
			}

			.curalate-upload-info {
				.upload-info-fieldset {
					margin: 0 0 10px;
					padding: 0 0 10px;

					.field-group {
						width: 75%;

						input {
							width: 100%;
						}
					}
				}
			}

			.curalate-upload-edit {
				@apply tw-flex tw-flex-col;
				flex: 1 1 auto;

				.curalate-upload-edit-fieldset-container {
					flex-basis: calc(100% - 30px);

					.curalate-upload-edit-fieldset {
						@apply tw-mt-1 md:tw-mt-5;
						.upload-edit-fieldset {
							@apply tw-flex tw-flex-col;
							height: calc(100% - 10px);
							margin: 0 0 10px;

							.upload-edit-noImages {
								@apply tw-flex tw-flex-col tw-flex-nowrap tw-justify-center tw-items-center;
								@apply tw-flex-grow;

								.upload-edit-noImages-text {
									@apply tw-my-8 md:tw-my-16;
									text-align: center;
								}
							}

							.upload-edit-field-group {
								@apply tw-flex tw-flex-row tw-flex-nowrap tw-justify-between tw-items-center;
								margin: 10px 0;

								.upload-edit-image-container {
									@apply tw-flex tw-flex-col tw-items-center;
									flex: 0 0 50px;
									margin: 0 10px 0 0;

									.upload-edit-image {
										max-height: 50px;
									}
								}

								.upload-edit-control-container {
									flex: 1 0 140px;
									margin: 0 10px 0 0;

									.upload-edit-control {
										margin: 0;
										width: 100%;
									}
								}
							}
						}
					}
				}
			}

			.curalate-upload-controls {
				@apply tw-flex tw-flex-row tw-flex-nowrap tw-justify-between;
			}
		}
	}

	@screen smOnly {
		.curalate-upload-container {
			.curalate-upload-form {
				.curalate-upload {
					@apply tw-flex tw-flex-col;
				}

				.curalate-upload-tabset {
					flex-basis: auto;
				}

				.curalate-upload-files {
					.files-field-group-heading, .files-field-group-label-before {
						display: none;
					}

					.files-label-curalate-upload {
						@apply tw-text-white tw-no-underline;
						text-transform: capitalize;
					}

					.files-label-curalate-upload:hover {
						@apply tw-text-black;
					}

					:global .files-control-curalate-upload {
						display: none;
					}
					:global .upload-files-fieldset {
						margin: 0;
						width: 100%;
					}
					.ButtonSecondary {
						line-height: 18px;
						margin: 40px 0;
					}
					.upload-files-droppable {
						border: none;
						padding: 0;
						text-align: center;

						.files-field-group {
							margin: 0;
						}
					}
				}

				.curalate-upload-info {
					flex-basis: auto;

					.upload-info-fieldset {
						border-bottom: none;
						margin: 0;
						padding: 0;

						.field-group {
							width: 100%;
						}
					}
				}

				.curalate-upload-edit {
					@apply tw-border-t tw-border-solid tw-border-gray-400 tw-border-l-0;
					margin: 10px 0 0;
					padding: 10px 0 0;
				}
			}
		}
	}
}
