@media screen {
	.curalate-header {
		@apply tw-flex tw-flex-col tw-items-center;
		margin: 0 0 25px;

		.curalate-heading {
			font-size: 24px;
			font-weight: 300;
			margin: 0 0 5px;
		}

		.curalate-subheading {
			margin: 0 0 5px;
			text-align: center;
		}
	}


	.curalate-container {
		margin: 0 0 50px;
		padding: 20px 0 50px;


		:global(.slick-track) {
			@apply tw-items-stretch #{!important};
		}

		:global(.slick-slide) {
			@apply tw-flex tw-flex-col;
			height: unset;

			> div {
				height: 100%;
			}
		}

		:global(.curalate-item-placeholder-plus) {
			@apply tw-text-xl;
		}

		&.curalate-container-no-items {
			padding: 40px 0 0;
		}



		:global .slick-slider .slick-nav-button {
			@apply tw-bg-white tw-items-center tw-justify-center tw-rounded-full tw-z-10;
			// !important position: absolute rule to override .tw-not-sr-only which sets position: static and auto width/height
			@apply tw-absolute tw-flex #{!important};
			bottom: calc(50% - 15px);
			height: 35px !important;
			opacity: 0.9;
			width: 35px !important;

			&:disabled {
				@apply tw-opacity-25;
			}

			&:before {
				@apply tw-block tw-border-gray tw-border-solid;
				content: '';
				height: 16px;
				width: 16px;
			}

			&:focus,
			&:hover {
				&:not(:disabled) {
					@apply tw-bg-gray tw-opacity-100;
					// !important position: absolute rule to override .tw-not-sr-only which sets position: static and auto width/height
					@apply tw-absolute #{!important};
					height: 35px !important;
					width: 35px !important;

					&:before {
						@apply tw-border-white;
					}
				}
			}
		}

		:global .slick-slider .slick-nav-button-next {
			@apply tw-right-0;

			&:before {
				transform: rotate(-45deg) translateX(-3px) translateY(-3px);
				@apply tw-border-b tw-border-r tw-transform tw--rotate-45 tw--translate-x-1;
			}
		}

		:global .slick-slider .slick-nav-button-prev {
			@apply tw-left-0;

			&:before {
				transform: rotate(45deg) translateX(-3px) translateY(3px);
				@apply tw-border-b tw-border-l tw-transform tw-rotate-45 tw-translate-x-1;
			}
		}

		:global .slick-slider .slick-slide {
			text-align: center;
		}

		.curalate-before {
			@apply tw-flex tw-flex-row tw-flex-nowrap tw-justify-between tw-items-start;

			.curalate-before-heading {
				font-size: 16px;
				font-weight: bold;
				margin: 0;
				text-transform: uppercase;

				.curalate-before-heading-hashtag {
					@apply tw-text-navy;
					margin-left: 10px;
				}
			}
		}

		.curalate-item-button {
			&:not(:focus):not(:hover) {
				.curalate-item-username-container {
					@apply tw-sr-only;
				}
			}

			.curalate-item-username-container {
				@apply tw-flex tw-flex-col tw-flex-nowrap tw-justify-center;
				background-color: rgba(0, 0, 0, 0.6);
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;

				.curalate-item-username {
					@apply tw-text-white;
					overflow: hidden;
					padding: 10px;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
		}

		:global .slick-slide {
			@apply tw-mr-[20px] md:tw-mx-[7px];

			:local(.curalate-item-button) {
				@apply tw-relative tw-w-full;

				&:focus, &:hover {
					@apply tw-outline-1 tw-outline-dotted tw-outline-offset-2 tw-outline-gray-300;

					.curalate-item-username-container {
						@apply tw-hidden;
					}
				}

				img {
					max-height: 100%;
					width: 100%;
				}
			}
		}

		&.curalate-list-container {
			@apply tw-border-0 tw-py-0 tw-mb-0;

			.curalate-before {
				@apply tw-m-2;

				.curalate-filter {
					@apply tw-flex;

					.curalate-filter-label {
						@apply tw-mr-2;
					}
				}
			}

			.curalate-item-list {
				@apply tw-gap-[20px] tw-grid tw-grid-flow-col tw-justify-start tw-w-screen tw-overflow-x-auto tw-p-[5px] md:tw-grid-cols-4 md:tw-grid-flow-row md:tw-w-auto;

				.curalate-item-button {
					@apply tw-flex-shrink-0 tw-relative tw-w-[290px] md:tw-w-auto;

					&:not(:focus):not(:hover) {
						.curalate-item-username-container {
							@apply tw-sr-only;
						}
					}

					.curalate-item-username-container {
						@apply tw-flex tw-flex-col tw-flex-nowrap tw-justify-center;
						background-color: rgba(0, 0, 0, 0.6);
						height: 100%;
						left: 0;
						position: absolute;
						top: 0;
						width: 100%;

						.curalate-item-username {
							@apply tw-text-white;
							overflow: hidden;
							padding: 10px;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					}
				}

				.curalate-item-button {
					&:focus, &:hover {
						@apply tw-outline-1 tw-outline-dotted tw-outline-offset-2 tw-outline-gray-300;

						.curalate-item-username-container {
							@apply tw-hidden;
						}
					}
				}
			}

			.curalate-item-list-exposedSm, .curalateItemListCareers {
				@apply tw-gap-[10px] tw-grid-cols-2 tw-grid-flow-row tw-w-auto md:tw-gap-[15px] md:tw-grid-cols-4;

				.curalate-item-button {
					@apply tw-w-auto;
				}
			}

			.curalate-load-more {
				@apply tw-py-8;
			}
		}

		&.curalateContainerCareers {
			@apply tw-border-0 tw-mb-0;
			padding: 66px 66px 85px;

			:global .slick-slide {
				width: 215px;
				flex: 0 0 215px;

				img {
					@apply tw-w-full;
				}
			}

			:global .slick-slider .slick-nav-button {
				@apply tw-static #{!important};
				@apply tw-outline-none tw-m-auto tw-shadow-none;
				padding: 4px;

				&:before {
					@apply tw-block tw-border-transparent tw-border tw-transform-none;
					border-width: 10px 0;
					content: '';
				}

				&:focus, &:hover {
					&:not(:disabled) {
						@apply tw-bg-transparent;
						@apply tw-static #{!important};

						&:before {
							@apply tw-border-l-orange tw-border-r-orange;
						}
					}
				}
			}

			:global .slick-slider {
				@apply tw-flex tw-justify-center;
			}

			:global .slick-list {
				width: 920px;
			}

			.curalate-item-button {
				@apply tw-h-full;
			}

			:global .slick-slider .slick-nav-button-next {
				&:before {
					border-left: 10px solid #ccc;
				}
			}

			:global .slick-slider .slick-nav-button-prev {
				&:before {
					border-right: 10px solid #ccc;
				}
			}
		}
	}

	.curalate-no-items {
		img {
			width: 100%;
		}
	}

	.curalate-item-placeholder-button {
		@apply tw-flex tw-flex-col tw-flex-nowrap tw-justify-center tw-items-center;
		@apply tw-border tw-border-dashed tw-border-gray-400 tw-h-full;

		.curalate-item-placeholder-plus {
			@apply tw-text-gray-400;
			font-size: 80px;
			font-weight: bold;
			line-height: 0;
			position: relative;
			top: -3px;
		}
	}


	@screen md {
		.curalate-header {
			.curalate-heading {
				font-size: 32px;
			}

			.curalate-subheading {
				font-size: 19px;
				font-weight: 300;
			}

			.curalate-button {
				font-size: 19px;
				font-weight: 300;
			}
		}

		.curalate-container {
			margin: 0;
			padding: 30px 0;

			&.curalate-container-no-items {
				padding: 40px 0 50px;
			}

			:global .slick-slider .slick-list {
				margin: 0 30px;
			}
		}
	}
}

@media only screen and (max-width: 745px) {
	.curalate-container {
		&.curalateContainerCareers {
			padding: 25px 0px;
			margin-bottom: 40px;
			max-width: calc(100vw - 15px);
			:global .slick-slide {
				width: 320px;
				flex: 0 0 320px;
			}
		}
	}
}
